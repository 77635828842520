<template>
  <div class="user">
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加广告图</div>
        <div class="title" v-show="!openState">修改轮播图</div>
      </div>
      <el-form :model="form"  :rules="rules" ref="ruleForm">
        <el-form-item label="轮播图ID" prop="bannerId" :label-width="formLabelWidth">
         <el-select v-model="form.banner_id" placeholder="请选择">
    <el-option
      v-for="item in BannerList"
      :key="item.id"
      :label="item.label"
      :value="item.id">
    </el-option>
  </el-select>
        </el-form-item>
          <el-form-item label="广告图对应商品" prop="shoppingId" :label-width="formLabelWidth">
         <el-cascader  @change="onChangeShopping"  :props="{ checkStrictly: true, value:'id',label:'name',children:'sub' }"  v-model="form.goods_id" :options="shoppingList" :show-all-levels="false"></el-cascader>
        </el-form-item>
          <el-form-item label="广告图标题" prop="title" :label-width="formLabelWidth">
    <el-input v-model="form.title" style="width:217px;"></el-input>
  </el-form-item>
        <el-form-item label="上传广告图" prop="imgUrl" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
            :data="imgParam"
          >
            <el-button size="small" type="primary">上传广告图</el-button>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,长宽尺寸比2.27:1</div>
            <div slot="tip" class="el-upload__tip">最小尺寸700x308,否则会造成小程序轮播图模糊</div> -->
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转类型" prop="title" :label-width="formLabelWidth">
          <el-select v-model="form.jump_type" placeholder="请选择">
    <el-option
      v-for="item in optionsType"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
  </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd('ruleForm')" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit('ruleForm')" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除广告图</div>
      </div>
      <div style="margin-bottom: 10px">
          <div class="image">
              <img
                :src="userdelinfo.img_url"
                alt=""
                width="120"
                v-if="userdelinfo.img_url"
              />
          </div></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addOpen()">添加</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="600"
      >
        <el-table-column prop="url" label="广告图图片">
          <template slot-scope="scope">
            <div class="image">
              <img
                :src="scope.row.img_url"
                alt=""
                width="120"
                v-if="scope.row.img_url"
              />
            </div>
          </template>
        </el-table-column>
          <el-table-column prop="url" label="商品图图片">
          <template slot-scope="scope">
            <div class="image">
              <img
                :src="scope.row.goods_img"
                alt=""
                width="120"
                v-if="scope.row.goods_img"
              />
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="goods_name" label="商品名称"></el-table-column>
         <el-table-column prop="title" label="广告图标题"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="changeState(scope.row.id)"
              v-model="scope.row.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="editOpen(scope.row)"
            ></el-button>
            <el-button
              type="danger"
              size="mini"
              icon="el-icon-delete"
              @click="delOpen(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  bannerLinkList,
  updateBannerLink,
  addBannerLink,
  getBannerList,
  deleteBannerLink,
  getGoodsList
  } from "@/api/advertising"

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {}, 
  data() {
     var validateBannerId = (rule, value, callback) => {
        if (this.form.banner_id === '') {
          callback(new Error('请选择轮播图'));
        } else {
          callback();
        }
      };
        var validateShoppingId = (rule, value, callback) => {
        if (this.form.goods_id.length==0) {
          callback(new Error('请选择对应商品'));
        } else {
          callback();
        }
      };
       var validateImgUrl = (rule, value, callback) => {
        if (this.form.img_url === '') {
          callback(new Error('请传入广告图'));
        } else {
          callback();
        }
      };
       var validateTitle = (rule, value, callback) => {
        if (this.form.title === '') {
          callback(new Error('请输入广告图标题'));
        } else {
          callback();
        }
      };
    //这里存放数据
    return {
       rules: {
          bannerId: [
            { validator: validateBannerId, trigger: 'blur' }
          ],
          imgUrl: [
            { validator: validateImgUrl, trigger: 'blur' }
          ],
          shoppingId:[{
            validator: validateShoppingId, trigger: 'blur'
          }],
          title:[{
            validator: validateTitle, trigger: 'blur'
          }],
        },
      token: {
        'Accept-Token': window.sessionStorage.getItem("token"),

      },
      optionsType:[{
        value:1,
        label:'商品详情',
      },{
        value:2,
        label:'商品分类',
      }],
      imgParam: {
        image: "baner",
      },
      dialogLog: {
        state: false,
      },
      spacer: "\u00a0\u00a0\u00a0",
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: "id",
        label: "name",
        children: "son",
      },
      //总页数
      exp: {
        total: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        cat_id: 1,
        info: "",
        page: 1,
        list_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: "一级",
        },
        {
          id: 2,
          name: "二级",
        },
      ],
      //上级列表
      options: [],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        img: "",
        img_url: "",
        banner_id:'',
        goods_id:'',
        title:'',
        jump_type:1,
        front_end_param:null,
        classification_id:null
      },
      //表单名称长度
      formLabelWidth: "120px",
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: "",
      //表格数据
      tableData: [],
      fileList: [],
      state: "0",
      list: [],
      BannerList:[],
      shoppingList:[],
      props:{
        value:'id',
        label:'name',
        children:'sub'
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onChangeShopping(value){
           if(value.length==2){
             let idOne=value[0];
             let idOneIndex=null;
             let idTwo=value[1];
             let idTwoInex=null;
             let arrOneId=[]
             let arrTwoId=[]
           this.shoppingList.forEach(e => {
            arrOneId.push(e.id)
           });
           idOneIndex=arrOneId.indexOf(idOne)
           this.shoppingList[idOneIndex].sub.forEach(e => {
            arrTwoId.push(e.id)
           });
           idTwoInex=arrTwoId.indexOf(idTwo)
          //  console.log(idOneIndex,idTwoInex)
           this.form.classification_id=value[value.length-1]
           this.form.front_end_param=`${idOneIndex},${idTwoInex}`
           }else if(value.length==1){
            let idOne=value[0];
             let idOneIndex=null;
             let arrOneId=[]
           this.shoppingList.forEach(e => {
            arrOneId.push(e.id)
           });
           idOneIndex=arrOneId.indexOf(idOne)
           this.form.classification_id=value[value.length-1]
           this.form.front_end_param=`${idOneIndex}+,+0`
           }
          

    },
    exceed() {
      this.$message({
        message: "数量超出",
        type: "warning",
      });
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      console.log(response)
      this.form.img_url = response.data.url;
    },

    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.img_url = "";
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.getInfo.list_num = value;
      this.catalogue();
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page = value;
      this.catalogue();
    },
    //打开添加
    addOpen() {
      this.openState = true;
      this.dialogFormVisible = true;
      this.form.cat_id = this.getInfo.cat_id;
    },
    //打开编辑
    editOpen(row) {
      console.log(row);
      this.form = row;
      this.openState = false;
      this.dialogFormVisible = true;
      this.fileList = [{ name: row.title, url: row.img_url }];
    },
    //编辑打开
    async setEdit(formName) {
           this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log( Array.isArray(this.form.goods_id))
               Array.isArray(this.form.goods_id)?this.form.goods_id=this.form.goods_id[this.form.goods_id.length-1]:this.form.goods_id=this.form.goods_id;
                this.updateBannerLink(this.form);
                console.log(111)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true;
      this.userdelinfo = row;
    },
    // 删除
    async setDel() {
      let info = await deleteBannerLink({
        id: this.userdelinfo.id,
      });
      this.judge(info);
    },
    //修改
    async updateBannerLink(data){
         let info = await updateBannerLink(data);
      this.judge(info);
    },
    //搜索事件
    search(e) {
      console.log("搜索信息: " + e);
    },
    //添加事件--提交事件
    async setAdd(formName) {
       this.$refs[formName].validate((valid) => {
          if (valid) {
                this.form.goods_id=this.form.goods_id[this.form.goods_id.length-1];
                this.addBannerLink(this.form);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
   
    },
    async changeState(id){
      let info= await getChange({id:id})
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 1) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.dialogVisible = false;
        this.bannerLinkList();
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        img: "",
        url: "",
        order: "",
      };
      (this.fileList = []), console.log("close");
    },
    async addBannerLink(data){
        let res=await addBannerLink(data);
        this.judge(res);
    },
    //轮播图列表
    async getBannerList(){
       let res=await getBannerList();
       this.BannerList=res.data.data
    },
    //商品列表
    async getGoodsList(){
       let res=await getGoodsList();
       this.shoppingList=res.data.data;  
    },

    //目录接口
    async bannerLinkList() {
      let info = await bannerLinkList();
      this.tableData = info.data.data.data;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.bannerLinkList();
    this.getBannerList();
    this.getGoodsList();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.border {
  display: flex;
}
.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}
.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}
.image {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
  overflow: hidden;
}
</style>